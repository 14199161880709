import { Component, Fragment } from "react";
import React from "react";
import { Card, CardDeck } from "react-bootstrap";
import vege from "../images/vege.jpg";
import fruits from "../images/fruits.jpg";
import frozen from "../images/frozen.jpg";
import beet from "../images/beet.jpg";
import Background from "./backgroung";

class Home extends Component {
  render() {
    return (
      <Fragment>
        <Background />
        <div className="container-lg">
          <div className="main">
            <h1 className="h1side">
              <span className="span1">About</span>
              <span> us</span>

              <div className="Home">
                <p className="home-p">
                  NOUR HARVEST is emanation of agriculture expertise and
                  proficiency that was built over years of hand on practice and
                  learning. The company is renowed as leading provider of fully
                  integrated agronomy,agriculture mechanization,supervision and
                  farms management.
                </p>
              </div>
            </h1>

            <div className="side"></div>
          </div>
          <CardDeck expand="lg">
            <Card>
              <Card.Img variant="top" src={beet} />
              <Card.Body>
                <Card.Title>Sugar Beet</Card.Title>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={vege} />
              <Card.Body>
                <Card.Title>Vegetables</Card.Title>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={fruits} />
              <Card.Body>
                <Card.Title>Fruits</Card.Title>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src={frozen} />
              <Card.Body>
                <Card.Title>Frozen and Dry Food</Card.Title>
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      </Fragment>
    );
  }
}

export default Home;
