import { Fragment } from "react";
import { Component } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import logo from "../images/face.png";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <div className="Footer">
          <div className="container-lg">
            <Row className="row-footer">
              <Col className="col-footer">
                <div className="footerimage"></div>
              </Col>
              <Col>
                <div>
                  <Nav.Link href="/" className="footer-t">
                    Home
                  </Nav.Link>
                  <Nav.Link href="/Ourservice" className="footer-t">
                    Our service
                  </Nav.Link>
                  <Nav.Link href="/Contact" className="footer-t">
                    Contact Us
                  </Nav.Link>
                </div>
              </Col>
              <Col>
                <span className="footer-t">Contact us </span>
                <Nav.Link
                  href="https://m.facebook.com/Nour-Harvest-105566901870174/?__tn__=C-R"
                  className="footer-t"
                >
                  <img className="social" src={logo} alt="logo"></img>
                  <span className="footer-t footer-left">Nour Harvest </span>
                </Nav.Link>
              </Col>
            </Row>
          </div>
        </div>
        <span className="trade">&copy; 2021 designed by Attaa sobhy </span>
      </Fragment>
    );
  }
}
export default Footer;
