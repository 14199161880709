import { Component, Fragment } from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../images/33.png";

class Navigation extends Component {
  state = {
    isTop: true,
    class: "header",
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const istoptop = window.scrollY < 90;
      if (istoptop !== this.state.isTop) {
        this.setState({ isTop: true, class: "sticky" });
      } else if (istoptop === this.state.isTop) {
        this.setState({ isTop: true, class: "header" });
      }
    });
  }

  render() {
    return (
      <Fragment>
        <Navbar className={this.state.class} expand="sm" variant="light">
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" height="60vw"></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ml-auto my-2 my-lg-0"
              style={{ maxHeight: "none" }}
              navbarScroll
            >
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/About">About</Nav.Link>
              <Nav.Link href="/Ourservice">Our service</Nav.Link>
              <Nav.Link href="/Contact">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Fragment>
    );
  }
}

export default Navigation;
