import { Component } from "react";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./home";

import Notfound from "./notfound";
import Navigation from "./navigation";
import Footer from "./footer";

const About = React.lazy(() => import("./about"));
const Ourservice = React.lazy(() => import("./ourservice"));
const Contact = React.lazy(() => import("./contact"));

//routing

class Routing extends Component {
  render() {
    return (
      <Router>
        <Navigation />

        <main className="nav-color main-hight  ">
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/About" component={About} />
              <Route path="/Ourservice" component={Ourservice} />
              <Route path="/Contact" component={Contact} />
              <Route component={Notfound} />
            </Switch>
          </Suspense>
        </main>
        <Footer />
      </Router>
    );
  }
}

export default Routing;
