import { Component } from "react";
import { Carousel } from "react-bootstrap";

class Background extends Component {
  render() {
    return (
      <Carousel>
        <Carousel.Item interval={4000} variant="dark">
          <div className="banner"></div>
          <Carousel.Caption>
            <h3>Land Restoration</h3>
            <br />
            <p>
              The sustainable land management practices that target
              rehabilitation efforts
            </p>
            <p> Design and construction of modern irrigation systems. </p>
            <p>
              cultivation and management until production is regular at the
              target rates.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <div className="banner1"></div>

          <Carousel.Caption>
            <h3> Agriculture Mechanization</h3>
            <br />
            <p> Preparing land</p>
            <p> Seed drilling and planting</p>
            <p> weeding , crop spraying and Harvesting.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <div className="banner2"></div>

          <Carousel.Caption>
            <h3>Farm supervision</h3>
            <br />
            <p>
              Technical supervision of existing farms by providing the necessary
              expertise and efforts to improve the production process
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default Background;
